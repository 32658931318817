.public-DraftEditor-content .public-DraftStyleDefault-block {
  margin-top: 10px;
  margin-bottom: 18px;
}

.rdw-center-aligned-block > * {
  text-align: center;
}

.rdw-left-aligned-block > * {
  text-align: left;
}

.rdw-right-aligned-block > * {
  text-align: right;
}

.rdw-justify-aligned-block > * {
  text-align: justify;
}

.rdw-editor-main blockquote {
  border-left: 5px solid #033bcf;
}

.rdw-editor-main a {
  color: #033bcf;
  text-decoration-line: underline;
  text-decoration-color: #033bcf;
}
