@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'Nexus-Bold';
  src: url('./fonts/Nexa/Nexa-Bold.ttf') format('trueType');
}

@font-face {
  font-family: 'Nexus-Regular';
  src: url('./fonts/Nexa/Nexa-Regular.ttf') format('trueType');
} */

@font-face {
  /*700*/
  font-family: 'Figtree-Bold';
  src: url('./fonts/Figtree/Figtree-Bold.ttf') format('trueType');
}

@font-face {
  /*600*/
  font-family: 'Figtree-SemiBold';
  src: url('./fonts/Figtree/Figtree-SemiBold.ttf') format('trueType');
}

@font-face {
  /*500*/
  font-family: 'Figtree-Medium';
  src: url('./fonts/Figtree/Figtree-Medium.ttf') format('trueType');
}

@font-face {
  /*400*/
  font-family: 'Figtree-Regular';
  src: url('./fonts/Figtree/Figtree-Regular.ttf') format('trueType');
}

@font-face {
  font-family: 'Segoe-UI';
  src: url('./fonts/Segoe/Segoe-UI.ttf') format('trueType');
}

@font-face {
  font-family: 'Segoe-UI-Italic';
  src: url('./fonts/Segoe/Segoe-UI-Italic.ttf') format('trueType');
}

@font-face {
  font-family: 'Segoe-UI-Bold';
  src: url('./fonts/Segoe/Segoe-UI-Bold.ttf') format('trueType');
}

@font-face {
  font-family: 'Segoe-UI-Bold-Italic';
  src: url('./fonts/Segoe/Segoe-UI-Bold-Italic.ttf') format('trueType');
}
